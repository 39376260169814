import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./App.css";
import MainPage from "./Pages/Main/Main";
import HomePage from "./Pages/Home/Home";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />}>
          <Route path="" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
      <ScrollToTop />
    </Router>
  );
}

export default App;
